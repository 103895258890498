<template>
  <b-form-select
    :value="selectable_language"
    @change="change"
    class="w-auto ml-1"
    :title="$t('global.info.field_translatable')"
    v-b-tooltip.hover
  >
    <b-form-select-option
      v-for="(lang, key) in available_languages"
      :key="'lang'+key"
      :value="lang.code"
    >
      {{ lang.code }}
    </b-form-select-option>
    <b-form-select-option value="all">
      {{ $t('global.value.all') }}
    </b-form-select-option>
  </b-form-select>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TranslationSelect",
  methods: {
    change(value) {
      this.$store.dispatch("layout/setSelectableLanguage", value);
    },
  },
  watch: {
    selectable_language: function (value) {
      this.$store.dispatch("layout/setSelectableLanguage", value);
    },
  },
  computed: {
    ...mapState({
      available_languages: (state) => state.layout.available_languages,
      selectable_language: (state) => state.layout.selectable_language,
    }),
  },
};
</script>
