import axios from "../services/api";

let default_root = '/bwapi/';
let root = default_root + 'dashboard';
// let config_name = 'dashboard';

/**
 * Mocking client-server processing
 */
// const _page = {
//     title: "TITRE",
//     icon: "home",
//     breadcrumbs: [{ text: "Accueil" }],
//     items: [
//         { title: "GRAPH 1" },
//         { title: "GRAPH 2", size: 3 },
//         { title: "GRAPH 3", size: 2 },
//         { title: "GRAPH 4", size: 2 },
//         { title: "GRAPH 5", size: 4 },
//     ]
// }

export default {
    getPage(callback) {
        // setTimeout(() => callback(_page), 100)
        axios.get(root)
            .then((response) => {
                callback(response.data);
            });
    },
    getChartDistribution(callback) {
        axios.get(root + "/distribution")
            .then((response) => {
                callback(response.data);
            });
    },
    getChartComparison(callback) {
        return axios.get(root + "/comparison")
            .then((response) => {
                callback(response.data);
            });
    },
    getChartInstanceData(callback) {
        axios.get(root + "/instance")
            .then((response) => {
                callback(response.data);
            });
    },
    getChartGlobalData(callback) {
        return axios.get(root + "/global")
            .then((response) => {
                callback(response.data);
            });
    },
    getChartServerMetrics(callback) {
        return axios.get(root + "/server-metrics")
            .then((response) => {
                callback(response.data);
            });
    },
    getChartServerMetrics48h(callback) {
        return axios.get(root + "/server-metrics-48h")
            .then((response) => {
                callback(response.data);
            });
    },
}